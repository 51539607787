<template>
  <div>
    <b-card>
      <div>
        <!-- Table Fitler -->
        <div class="mb-2">
          <!-- Search Condition -->
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3 mt-lg-1"
                label="사이트"
                label-for="site-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-if="authSite"
                  id="logs-site"
                  ref="logs-site"
                  v-model="selectedSite"
                  :options="authSite"
                  class="font-small-3"
                  size="sm"
                  style="color:rgb(229,166,48); "
                >
                  <template
                    v-if="false"
                    #first
                  >
                    <b-form-select-option :value="null">
                      전체
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group
                class="font-small-3"
                label-align-sm="3"
                label="조회일자"
                label-for="search-logs-period"
              >
                <b-input-group
                  id="logs-period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3"
                label="show"
                label-for="logs-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  size="sm"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group class="mb-0 pr-1">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="searchFilter"
                    type="search"
                    placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!searchFilter"
                      @click="searchFilter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            ref="logsUsersTable"
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            :per-page="perPage"
            :items="fetchLogsUsersVue"
            :fields="logsUsersFields"
            :filter="searchFilter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template #cell(index)="data">
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>

            <template #cell(site)="data">
              <div style="min-width: 5rem; text-align: left;" size="sm">
                {{ data.item.site }}
              </div>
            </template>

            <template #cell(baseDate)="data">
              <div style="min-width: 6rem;" size="sm">
                {{ data.item.baseDate }}
              </div>
            </template>

            <template #cell(title)="data">
              <div style="min-width: 6rem; color: #ceaa73" size="sm">
                {{ data.item.title }}
              </div>
            </template>

            <template #cell(titleSub)="data">
              <div style="min-width: 12rem; text-align: left; color: #ceaa73" size="sm">
                {{ data.item.titleSub }}
              </div>
            </template>

            <template #cell(category)="data">
              <div style="min-width: 2rem; color: #ceaa73" size="sm">
                {{ data.item.category }}
              </div>
            </template>

            <template #cell(categorySub)="data">
              <div style="min-width: 5rem; color:rgb(138,196,146)" size="sm">
                {{ data.item.categorySub }}
              </div>
            </template>

            <template #cell(userid)="data">
              <div style="min-width: 5rem; text-align: left; color:rgb(138,196,146)" size="sm">
                {{ data.item.userid }}
              </div>
            </template>

            <template #cell(asisContent)="data">
              <div v-if="data.item.systemTypeDetail === 'CASH' || data.item.systemTypeDetail === 'POINT'"
                   style="min-width: 11rem; text-align: right; color:rgb(138,196,146)" size="sm">
                {{ Number(data.item.asisContent).toLocaleString() }}
              </div>
              <div v-else
                   style="min-width: 11rem; text-align: left; color:rgb(138,196,146)" size="sm">
                {{ data.item.asisContent }}
              </div>
            </template>

            <template #cell(tobeContent)="data">
              <div v-if="data.item.systemTypeDetail === 'CASH' || data.item.systemTypeDetail === 'POINT'"
                   style="min-width: 11rem; text-align: right; color:rgb(138,196,146)" size="sm"
                   v-b-tooltip.hover.right.v-danger="(Number(data.item.tobeContent) - Number(data.item.asisContent)).toLocaleString()"
              >
                {{ Number(data.item.tobeContent).toLocaleString() }}
              </div>
              <div v-else
                   style="min-width: 11rem; text-align: left; color:rgb(138,196,146)" size="sm">
                {{ data.item.tobeContent }}
              </div>
            </template>

            <template #cell(memo)="data">
              <div style="min-width: 15rem; text-align: left; color:rgb(138,196,146)" size="sm">
                {{ data.item.memo }}
              </div>
            </template>

            <template #cell(changeContent)="data">
              <div style="min-width: 11rem; text-align: left; color: #78bbcd" size="sm">
                {{ data.item.changeContent }}
              </div>
            </template>

            <template #cell(systemType)="data">
              <div style="min-width: 5rem;" size="sm">
                {{ data.item.systemType }}
              </div>
            </template>

            <template #cell(systemTypeDetail)="data">
              <div style="min-width: 5rem;" size="sm">
                {{ data.item.systemTypeDetail }}
              </div>
            </template>

            <template #cell(performer)="data">
              <div :style="{ color: data.item.performer === 'SYSTEM' ? '#ffea00' : 'inherit', minWidth: '5rem' }">
                {{ data.item.performer }}
              </div>
            </template>

            <template #cell(adminId)="data">
              <div style="min-width: 5rem;" size="sm">
                {{ data.item.adminId }}
              </div>
            </template>

            <template #cell(adminIp)="data">
              <div style="min-width: 5rem;" size="sm">
                {{ data.item.adminIp }}
              </div>
            </template>

            <template #cell(createdAt)="data">
              <div style="min-width: 11rem; text-align: right;" size="sm">
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </template>
          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span
                class="font-small-2"
              >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as moment from 'moment-timezone'
import { createNamespacedHelpers } from 'vuex'

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BTable,
  BPagination,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import router from '@/router'
import { FETCH_LOGS_USERS_VUE } from '@/store/logs/action'

const logStore = createNamespacedHelpers('logStore')

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BTable,
    BPagination,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      userIp: localStorage.getItem('ip'),
      selectedSite: localStorage.getItem('authSiteSelected'),
      pageRole: this.$route.path.split('/').pop(),
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      // TableOption
      searchFilter: '',
      perPageOptions: [25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 25, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      // TableContent
      logsUsersFields: [
        { key: 'index', label: 'No.' },
        { key: 'site', label: '사이트' },
        // { key: 'baseDate', label: '작업일자' },
        { key: 'title', label: '타이틀' },
        { key: 'titleSub', label: '서브타이틀' },
        { key: 'category', label: '구분' },
        { key: 'categorySub', label: '유형' },
        { key: 'userid', label: '변경유저명' },
        { key: 'asisContent', label: '변경전' },
        { key: 'tobeContent', label: '변경후' },
        { key: 'memo', label: '메모' },
        { key: 'changeContent', label: '컬럼' },
        { key: 'systemType', label: '타입' },
        { key: 'systemTypeDetail', label: '타입상세' },
        { key: 'performer', label: '작업주체' },
        { key: 'adminId', label: '작업자ID' },
        { key: 'adminIp', label: '작업자IP' },
        { key: 'createdAt', label: '작업일시' },
      ],
    }
  },
  computed: {
    ...logStore.mapGetters({
      fetchLogsUsersVue: 'fetchLogsUsersVue',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.fetchLogsUsersVue
      if (this.selectedSite) {
        filteredData = filteredData.filter(item => item.site.includes(this.selectedSite))
      }
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }
      return filteredData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...logStore.mapActions({
      $fetchLogsUsersVue: FETCH_LOGS_USERS_VUE,
    }),
    async fetchData() {
      const param = {
        site: this.selectedSite,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      }
      await this.$fetchLogsUsersVue(param)
      this.totalRows = this.fetchLogsUsersVue.length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style></style>
